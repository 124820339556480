<script>


</script>

<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="13.25" y1="1.25" x2="13.25" y2="6.25" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
    <line x1="13.25" y1="19.75" x2="13.25" y2="24.75" stroke="#6251B4" stroke-width="2.5" stroke-linecap="round"/>
    <line x1="1.25" y1="13.25" x2="6.25" y2="13.25" stroke="#4A42AC" stroke-width="2.5" stroke-linecap="round"/>
    <line x1="19.75" y1="13.25" x2="24.75" y2="13.25" stroke="#A592FF" stroke-width="2.5" stroke-linecap="round"/>
    <line x1="4.86835" y1="4.51465" x2="8.40389" y2="8.05018" stroke="#4533B7" stroke-width="2.5" stroke-linecap="round"/>
    <line x1="17.9499" y1="17.5962" x2="21.4854" y2="21.1317" stroke="#8874E4" stroke-width="2.5" stroke-linecap="round"/>
    <line x1="4.86841" y1="21.4852" x2="8.40394" y2="17.9496" stroke="#5142AC" stroke-width="2.5" stroke-linecap="round"/>
    <line x1="17.9497" y1="8.40386" x2="21.4852" y2="4.86833" stroke="#CABEFF" stroke-width="2.5" stroke-linecap="round"/>
</svg>

<style>
    svg {
        transform-origin: center center;
        height: 20px;
        width: 20px;
        animation: rotate 4s steps(8, end) infinite;
    }

    @keyframes rotate {
        0% {
            transform: rotate(360deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
</style>